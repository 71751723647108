/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout, Main } from '../../AdminTeamCreation/styles';
import {
  ChallengeHeading, Container, AddTaskButtonWrapper, AddTaskButton, InputContainer,
  StyledLabel, StyledInputV2, CustomRadioButton, UploadPhotoContainer, RemoveImage,
  InputImage, CustomTextArea, Button, ButtonContainer
} from './styles';
import StepsHeading from './stepsName';
import Waiting from '../../Waiting';
import { getOrientation, resetOrientation, checkImage } from '../../../utils/methods';
import { toast } from 'react-toastify';
import { imageErrorMessage } from '../../../utils/constants';
import { connect } from 'react-redux';
import { saveChallengeInformation, getChallengeInformation } from '../../../redux/actions';

class CreateChallengeThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: 1,
      step: 3,
      taskCount: 1,
      behaviorArr: [],
      radio: null,
      imgSrc: null,
      description: '',
      taskCountArray: [1],
      selectedTaskIndex: 0
    }
  }

  componentDidMount() {
    const { challengeData } = this.props;
    this.fillBehavior();
    getChallengeInformation();
    if (challengeData && challengeData.weeksData) {
      this.setState({
        behaviorArr: challengeData.weeksData
      });
    }
  }

  componentDidUpdate() {
    this.fillBehavior();
    if (this.props.challengeData && this.props.challengeData.taskCountArray && this.state.taskCountArray != this.props.challengeData.taskCountArray) {
      this.setState({
        taskCountArray: this.props.challengeData.taskCountArray
      });
    }
  }

  fillBehaviorData = () => {
    const { behaviorArr } = this.state;
    if (behaviorArr[0].challenge_title.length === 0) {
      this.props.saveChallengeInformation("weeksData", this.state.behaviorArr)
    }
  }

  fillBehavior = () => {
    if (this.state.behaviorArr.length == 0) {
      let arr = []
      for (let index = 0; index < this.state.selectedTaskIndex + 1; index++) {
        let obj = {
          image: null,
          imageName: '',
          tip: '',
          video: '',
          video_title: '',
          about_the_day: '',
          has_video: 0,
          challenge_title: '',
          radio: null
        };
        arr.push(obj);
      }
      this.setState({
        behaviorArr: arr
      }, () => {
        this.fillBehaviorData();
      });
    }
  }

  handleTaskCount = () => {
    const { taskCountArray } = this.state;
    this.setState({
      taskCount: taskCountArray.length + 1,
      selectedTaskIndex: taskCountArray.length
    }, () => {
      this.taskCount();
    });
  };

  taskCount = () => {
    const { taskCount } = this.state;
    let arr = [];
    let obj = {
      image: null,
      imageName: '',
      tip: '',
      video: '',
      video_title: '',
      about_the_day: '',
      has_video: 0,
      challenge_title: '',
      radio: null
    };
    arr.push(obj);
    let prevArray = this.state.behaviorArr;
    prevArray.push(obj);
    this.setState({
      behaviorArr: prevArray
    })
    let newArr = this.state.taskCountArray;
    newArr.push(taskCount);
    this.setState({ taskCountArray: newArr })
  };

  showSelectedData = (index) => {
    this.setState({
      selectedTaskIndex: index
    })
  };

  getChallengeTitle = (e, index) => {
    let { behaviorArr } = this.state;
    let dubArr = behaviorArr;
    dubArr[index].challenge_title = e.target.value;
    this.setState({
      behaviorArr: dubArr
    });
  }

  getAboutChallenge = (e, index) => {
    let { behaviorArr } = this.state;
    let dubArr = behaviorArr;
    dubArr[index].about_the_day = e.target.value;
    this.setState({
      behaviorArr: dubArr
    })
  }

  getChallengeTip = (e, index) => {
    let { behaviorArr } = this.state;
    let dubArr = behaviorArr;
    dubArr[index].tip = e.target.value;
    this.setState({
      behaviorArr: dubArr
    })
  }

  getChallengeVideo = (e, index) => {
    let { behaviorArr } = this.state;
    let dubArr = behaviorArr;
    dubArr[index].video = e.target.value;
    this.setState({
      behaviorArr: dubArr
    })
  }

  getDailyImage = (e, index) => {
    let { behaviorArr } = this.state;
    let dubArr = behaviorArr;
    let file = e.target.files[0];
    if (checkImage(e.target.files)) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      const array = document.getElementById(`daily-file${index}`).value.split("\\");
      reader.onloadend = () => {
        getOrientation(file, (or) => {
          resetOrientation([reader.result], or, (baseImage) => {
            dubArr[index].image = baseImage;
            dubArr[index].imageName = array[array.length - 1];
            this.setState({
              behaviorArr: dubArr
            });
            document.getElementById(`daily-file${index}`).value = '';
          });
        });
      };
    } else {
      toast.error(imageErrorMessage);
      document.getElementById(`daily-file${index}`).value = '';
    }
  }

  removeDailyPhoto = (index) => {
    let { behaviorArr } = this.state;
    let dubArr = behaviorArr;
    // document.getElementById(`daily-file${index}`).value = '';
    dubArr[index].image = null;
    dubArr[index].imageName = '';
    this.setState({
      behaviorArr: dubArr
    })
  }

  checkBase64(image) {
    if (image) {
      if (image.includes('base64')) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  getRadioButtonStatus = (value, index, status) => {
    let { behaviorArr } = this.state;
    let dubArr = behaviorArr;
    if (status === "video") {
      dubArr[index].radio = value;
    }
    if (status === "image") {
      dubArr[index].radio = value;
    }
    this.setState({
      behaviorArr: dubArr
    })
  }

  redirection = (value) => {
    const { taskCountArray } = this.state;
    const { history } = this.props;
    this.props.saveChallengeInformation("taskCountArray", taskCountArray);
    history.push(value);
  }

  handleRemove = (indexValue) => {
    let updatedArray = this.state.behaviorArr.filter((obj, index) => index != indexValue);
    let newArr = this.state.taskCountArray;
    newArr.pop(indexValue);
    this.setState({
      taskCount: updatedArray.length,
      selectedTaskIndex: 0,
      behaviorArr: updatedArray,
      taskCountArray: newArr
    })
    this.props.saveChallengeInformation("weeksData", updatedArray)
  }

  render() {
    const { step, taskCountArray, selectedTaskIndex, behaviorArr } = this.state;
    if (behaviorArr.length === 0) {
      return <Waiting />
    }
    return (
      <React.Fragment>
        <Layout marginBottom="0px">
          <Main>
            <ChallengeHeading>
              Create a Wellness Challenge
            </ChallengeHeading>
            <Container disable='1' background={'none'}>
              <div className="stepsNameHighlight">
                <StepsHeading stepCount={step} />
              </div>
              <div className="form">
                <div className="heading">
                  <div className="step">3</div>
                  <div className="headingName">Add Daily Tasks</div>
                </div>
                <AddTaskButtonWrapper>
                  {taskCountArray && taskCountArray.map((task, index) => (
                    <AddTaskButton active={selectedTaskIndex === index ? true : false} key={index} onClick={() => this.showSelectedData(index)}>
                      Task {task}
                    </AddTaskButton>))}
                  <AddTaskButton addTask='1' onClick={() => this.handleTaskCount()}>
                    + Task
                  </AddTaskButton>
                </AddTaskButtonWrapper>
                <div className="taskHeading">
                  <div className="task">Task {selectedTaskIndex + 1}</div>
                  <div className="imageWrapper">
                    <img src={"/images/CompanyDashBoardV2/EditTip.png"} />
                    <img src={"/images/CompanyDashBoardV2/DeleteTip.png"} onClick={() => this.handleRemove(selectedTaskIndex)} />
                  </div>
                </div>

                <div className="formBody">
                  <InputContainer>
                    <StyledLabel>Title of the Challenge{<span>*</span>}</StyledLabel>
                    <StyledInputV2
                      type={"text"}
                      placeholder={"Enter Name of the Challenge"}
                      name={"Challenge Name"}
                      maxLength={100}
                      onChange={(e) => this.getChallengeTitle(e, selectedTaskIndex)}
                      value={(selectedTaskIndex + 1 <= behaviorArr.length) ? behaviorArr[selectedTaskIndex].challenge_title : ''}
                    />
                  </InputContainer>
                  <StyledLabel>What you want to add with this Challenge?{<span>*</span>}</StyledLabel>
                  <div className='radioButtonDiv'>
                    <span><CustomRadioButton onClick={() => this.getRadioButtonStatus(behaviorArr[selectedTaskIndex].radio == "video" ? null : "video", selectedTaskIndex, "video")} > {selectedTaskIndex + 1 <= behaviorArr.length && behaviorArr[selectedTaskIndex].radio == "video" && <div></div>} </CustomRadioButton> Video</span>
                    <span><CustomRadioButton onClick={() => this.getRadioButtonStatus(behaviorArr[selectedTaskIndex].radio == "image" ? null : "image", selectedTaskIndex, "image")}> {selectedTaskIndex + 1 <= behaviorArr.length && behaviorArr[selectedTaskIndex].radio == "image" && <div></div>} </CustomRadioButton> Image</span>
                  </div>
                  {selectedTaskIndex + 1 <= behaviorArr.length ?

                    (behaviorArr[selectedTaskIndex].radio == "image" ?
                      <UploadPhotoContainer>
                        <div className='innerDivImageContainer'>
                          <img src={selectedTaskIndex + 1 <= behaviorArr.length
                            ? behaviorArr[selectedTaskIndex].image ? behaviorArr[selectedTaskIndex].image : "/images/default.png"
                            : "/images/default.png"}
                          />
                          {behaviorArr[selectedTaskIndex].image == null ? <InputImage className="edit-profile-pic " imageReload={1}>
                            <i className="addImg">{"Upload Image"}</i>
                            <input
                              id={`daily-file${selectedTaskIndex}`}
                              type="file"
                              name="daily-user"
                              multiple={false}
                              onChange={(e) => this.getDailyImage(e, selectedTaskIndex)}
                              onClick={(e) => e.target.files[0] && this.getDailyImage(e, selectedTaskIndex)}
                              accept=".jpeg, .png, .jpg"
                            />
                          </InputImage> :
                            <RemoveImage onClick={() => this.removeDailyPhoto(selectedTaskIndex)}>{"Remove Image"}</RemoveImage>}
                        </div>
                      </UploadPhotoContainer> :
                      behaviorArr[selectedTaskIndex].radio == "video" ?
                        <InputContainer>
                          <StyledLabel>Introduction video Link</StyledLabel>
                          <StyledInputV2
                            type="url"
                            pattern="https?://.+"
                            placeholder={"Paste Here your intro Video link"}
                            name={"Challenge Video Link"}
                            maxLength={100}
                            onChange={(e) => this.getChallengeVideo(e, selectedTaskIndex)}
                            value={(selectedTaskIndex + 1 <= behaviorArr.length) ? behaviorArr[selectedTaskIndex].video : ''}
                          />
                        </InputContainer> : null) : null}

                  <InputContainer>
                    <StyledLabel>About the Day{<span>*</span>}</StyledLabel>
                    <CustomTextArea
                      type={"text"}
                      placeholder={"Write About The Day"}
                      name={"aboutTheDay"}
                      onChange={(e) => this.getAboutChallenge(e, selectedTaskIndex)}
                      value={(selectedTaskIndex + 1 <= behaviorArr.length) ? behaviorArr[selectedTaskIndex].about_the_day : ''}
                    />
                  </InputContainer>
                  <InputContainer>
                    <StyledLabel>Daily Tip <div className="charCount">{selectedTaskIndex + 1 <= behaviorArr.length ? behaviorArr[selectedTaskIndex].tip.length : 0}/250 Characters</div></StyledLabel>
                    <CustomTextArea
                      type={"text"}
                      placeholder={"Write Daily Nudges here"}
                      name={"tip"}
                      onChange={(e) => this.getChallengeTip(e, selectedTaskIndex)}
                      value={(selectedTaskIndex + 1 <= behaviorArr.length) ? behaviorArr[selectedTaskIndex].tip : ''}
                    />
                  </InputContainer>
                </div>

              </div>
            </Container>
          </Main>
        </Layout>
        <ButtonContainer>
          <div className="wrapper">
            <Button color={'white'} textColor={'#9c9c9c'} border={'#9c9c9c'} width={"113px"} marginLeft="1" onClick={() => this.redirection('/company/challenges/create/step2')}>
              {"Go Back"}
            </Button>
            <Button border={"#9FC989"} disabled={!(behaviorArr.length > 2)} color="#9FC989" textColor="white" marginRight="1" marginAuto="1">
              Save & Add Task
            </Button>
            <Button disabled={!(behaviorArr.length > 2)} onClick={() => this.redirection('/company/challenges/create/step4')} >
              {"Next >>"}
            </Button>
          </div>
        </ButtonContainer>
      </React.Fragment>
    );
  }
}

CreateChallengeThree.propTypes = {
  challengeData: PropTypes.object,
  saveChallengeInformation: PropTypes.func,
  getChallengeInformation: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = (state) => ({
  challengeData: state.challengesAdmin.challengeData
});

const mapDispatchToProps = (dispatch) => ({
  saveChallengeInformation: (name, value) => dispatch(saveChallengeInformation(name, value)),
  getChallengeInformation: () => dispatch(getChallengeInformation())
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateChallengeThree);

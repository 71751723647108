/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout, Main, TitleContainer, DropdownArrow, ActivityDropdown, CustomMenuItem, FieldTitle } from '../../AdminTeamCreation/styles';
import { ChallengeHeading, Container, Button, ButtonContainer } from './styles';
import StepsHeading from './stepsName';
import { wellnessCategories, selectBehaviorLevel } from '../../../../mockData';
import { connect } from 'react-redux';
import { saveChallengeInformation, getChallengeInformation } from '../../../redux/actions';

class CreateChallengeTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: 1,
      step: 2,
      selectCategory: "",
      so: false,
      selectQuestion: "",
      selectLevel: ""
    }
  }

  componentDidMount() {
    const { getChallengeInformation, challengeData } = this.props;
    getChallengeInformation();
    this.setState({
      selectCategory: challengeData.selectCategory,
      selectQuestion: challengeData.selectQuestion,
      selectLevel: challengeData.selectLevel
    });
  }

  onSelectCategory = (name, value) => {
    this.setState({
      [name]: value
    });
    this.props.saveChallengeInformation(name, value)
  };

  render() {
    const { step, selectCategory, selectLevel, selectQuestion } = this.state;
    const { history } = this.props;
    return (
      <React.Fragment>
        <Layout marginBottom="0px">
          <Main>
            <ChallengeHeading>
              Create a Wellness Challenge
            </ChallengeHeading>
            <Container>
              <div className="stepsNameHighlight">
                <StepsHeading stepCount={step} />
              </div>
              <div className="form">
                <div className="heading">
                  <div className="step">2</div>
                  <div className="headingName">Challenge Type and Goal</div>
                </div>
                <div className="formBody">
                  <TitleContainer fullWidth>
                    <FieldTitle>{"Wellness Challenge Category"}{<span>*</span>}</FieldTitle>
                    <DropdownArrow alt='image' drop={this.state.so} src={this.state.so ? "/public/images/teamDownArrow.svg" : "/public/images/dropdown_arrow.svg"} />
                    <ActivityDropdown
                      placeholder="Select Option"
                      title={selectCategory ? selectCategory : "Select Wellness Challenge Category"}
                      id="dropdown-recurring"
                      onClick={() => this.setState({ so: !this.state.so })}
                    >
                      {
                        wellnessCategories.map((item, index) => (
                          <CustomMenuItem
                            eventKey={index}
                            key={index}
                            onSelect={() => this.onSelectCategory('selectCategory', item['value'])}
                            active={selectCategory == item['value']}
                          >
                            {item['value']}
                          </CustomMenuItem>
                        ))
                      }
                    </ActivityDropdown>
                  </TitleContainer>
                  <TitleContainer fullWidth>
                    <FieldTitle>{"Sub Level (WBA Questions)"}{<span>*</span>}</FieldTitle>
                    <DropdownArrow alt='image' drop={this.state.so} src={this.state.so ? "/public/images/teamDownArrow.svg" : "/public/images/dropdown_arrow.svg"} />
                    <ActivityDropdown
                      placeholder="Select Option"
                      title={selectQuestion ? selectQuestion : "Select Wellness Challenge Level"}
                      id="dropdown-recurring"
                      onClick={() => this.setState({ so: !this.state.so })}
                    >
                      {
                        wellnessCategories.map((item, index) => (
                          <CustomMenuItem
                            eventKey={index}
                            key={index}
                            onSelect={() => this.onSelectCategory('selectQuestion', item['value'])}
                            active={selectQuestion == item['value']}
                          >
                            {item['value']}
                          </CustomMenuItem>
                        ))
                      }
                    </ActivityDropdown>
                  </TitleContainer>
                  <TitleContainer fullWidth>
                    <FieldTitle>{"Wellness Challenge Level"}{<span>*</span>}</FieldTitle>
                    <DropdownArrow alt='image' drop={this.state.so} src={this.state.so ? "/public/images/teamDownArrow.svg" : "/public/images/dropdown_arrow.svg"} />
                    <ActivityDropdown
                      placeholder="Select Option"
                      title={selectLevel ? selectLevel : "Select Wellness Challenge Level"}
                      id="dropdown-recurring"
                      onClick={() => this.setState({ so: !this.state.so })}
                    >
                      {
                        selectBehaviorLevel.map((item, index) => (
                          <CustomMenuItem
                            eventKey={index}
                            key={index}
                            onSelect={() => this.onSelectCategory('selectLevel', item['value'])}
                            active={selectLevel == item['value']}
                          >
                            {item['value']}
                          </CustomMenuItem>
                        ))
                      }
                    </ActivityDropdown>
                  </TitleContainer>
                </div>
              </div>
            </Container>
          </Main>
        </Layout>
        <ButtonContainer>
          <div className="wrapper">
            <Button color={'white'} textColor={'#9c9c9c'} border={'#9c9c9c'} width={"113px"} marginLeft="1" onClick={() => history.push('/company/challenges/create/step1')}>
              {"Go Back"}
            </Button>
            <Button disabled={!selectCategory || !selectLevel || !selectQuestion} onClick={() => history.push('/company/challenges/create/step3')} marginAuto="1">
              {"Next >>"}
            </Button>
          </div>
        </ButtonContainer>
      </React.Fragment>
    );
  }
}

CreateChallengeTwo.propTypes = {
  history: PropTypes.object,
  challengeData: PropTypes.object,
  saveChallengeInformation: PropTypes.func,
  getChallengeInformation: PropTypes.func
};

const mapStateToProps = (state) => ({
  challengeData: state.challengesAdmin.challengeData
});

const mapDispatchToProps = (dispatch) => ({
  saveChallengeInformation: (name, value) => dispatch(saveChallengeInformation(name, value)),
  getChallengeInformation: () => dispatch(getChallengeInformation())
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateChallengeTwo);
